<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="auto">
          <v-btn class="label" text color="transparent">
            <v-icon color="secondary" left size="20">mdi-account</v-icon>
            <div class="font-weight-regular secondary--text text-capitalize">
              {{ $t('page_users_detail_title') }}
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-card class="business-info-card">
              <v-card-text>
                <div class="primary--text mb-2">{{ $t('business_info') }}</div>
                <div class="mb-2">
                  <div>
                    {{ singleUserCompany.id }} /
                    {{ singleUserCompany.display_name }}
                  </div>
                  <div>
                    {{ $t('business_type') }}:
                    {{
                      singleUserCompany.type !== null
                        ? singleUserCompany.type.name
                        : ''
                    }}
                  </div>
                  <div>
                    {{ singleUserCompany.postcode }}
                    {{
                      singleUserCompany.prefecture !== null
                        ? singleUserCompany.prefecture.name
                        : ''
                    }}
                    {{ singleUserCompany.city }}
                    {{ singleUserCompany.address }}
                  </div>
                  <div>{{ $t('tel') }}: {{ singleUserCompany.phone }}</div>
                </div>
                <div class="mb-2">
                  <div>
                    {{ $t('number_of_employee') }}:
                    {{
                      singleUserCompany.head_count !== null
                        ? singleUserCompany.head_count.name
                        : ''
                    }}
                  </div>
                  <div>
                    {{ $t('registration_date') }} :
                    {{ singleUserCompany.registration_date }}
                  </div>
                  <div>
                    {{ $t('registered') }} :
                    {{ singleUserCompany.registration_date }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col offset="2" cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4" md="4" class="text-right">
                        <span class="label-text">{{ $t('id') }}</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="primary--text">{{ singleUser.id }}</span>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6">
                    <v-row align="center">
                      <v-col cols="2">
                        <v-avatar class="white--text avatar-bg-grey" size="40">
                          <v-img
                            :src="!preview ? file : preview"
                            width="40"
                          ></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-for="field in fields" :key="field.name" cols="12">
                    <v-row align="center">
                      <v-col cols="4" md="2" class="text-right label-text">
                        {{ field.label }}
                      </v-col>
                      <v-col v-if="field.type === 'label'" cols="auto">
                        <span :class="field.color ? field.color + '--text' : ''"
                          >U10234</span
                        >
                      </v-col>

                      <template
                        v-else-if="
                          field.type === 'text' || field.type === 'email'
                        "
                      >
                        <v-col
                          cols="8"
                          :md="field.additional_field ? '4' : '8'"
                        >
                          <v-text-field
                            outlined
                            dense
                            flat
                            :type="field.type"
                            v-model="field.value"
                            :error-messages="errors"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="field.additional_field"
                          cols="8"
                          :md="field.additional_field ? '4' : '8'"
                          offset="4"
                          offset-md="0"
                        >
                          <v-text-field
                            outlined
                            dense
                            flat
                            v-model="field.additional_field.value"
                            :error-messages="errors"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </template>

                      <template v-else-if="field.type === 'groups'">
                        <v-col cols="8">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="field.name"
                            :rules="field.rules"
                          >
                            <v-autocomplete
                              v-model="selectedGroups"
                              :error-messages="errors"
                              :items="groups"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ maxHeight: '400' }"
                              :placeholder="field.placeholder"
                              multiple
                              dense
                              outlined
                              chips
                              small-chips
                              type="text"
                              style="pointer-events: none"
                            ></v-autocomplete>
                          </validation-provider>
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'index',

  created() {
    this.loading = true
    this.$store
      .dispatch('USER_GET', { id: this.$route.params.id })
      .then(() => {
        this.file = this.singleUser.photo
        this.fillFields()
      })
      .finally(() => (this.loading = false))
  },

  computed: {
    ...mapGetters(['singleUser', 'singleUserCompany']),
    groups() {
      return this.singleUser.groups
    }
  },

  data() {
    return {
      loading: false,
      errors: '',
      fields: [
        {
          label: this.$t('page_users_detail_field_name'),
          name: 'last_name',
          type: 'text',
          value: '',
          rules: 'required',
          additional_field: {
            type: 'text',
            name: 'first_name',
            rules: 'required',
            value: ''
          }
        },
        {
          label: this.$t('page_users_detail_field_furigana'),
          name: 'last_name_kana',
          type: 'text',
          value: '',
          rules: 'required',
          additional_field: {
            type: 'text',
            name: 'first_name_kana',
            rules: 'required',
            value: ''
          }
        },
        {
          label: this.$t('page_users_detail_field_position'),
          name: 'position',
          type: 'text',
          rules: '',
          value: ''
        },
        {
          label: this.$t('page_users_detail_field_email'),
          name: 'email',
          type: 'text',
          rules: 'required|email',
          value: ''
        },
        {
          label: this.$t('page_users_edit_field_group'),
          name: 'groups',
          type: 'groups',
          placeholder: this.$t('page_users_edit_field_group_placeholder'),
          value: '',
          rules: ''
        }
      ],
      formStatus: false,
      file: null,
      preview: null,
      selectedGroups: []
    }
  },

  methods: {
    fillFields() {
      let user = this.singleUser

      for (const field of this.fields) {
        if (field.type === 'link') continue
        if (field.additional_field) {
          field.additional_field.value = user[field.additional_field.name]
        }

        field.value = user?.[field.name]
        if (field.type === 'groups') {
          this.selectedGroups = []
          for (let i = 0; i < user.groups.length; i++) {
            let singleGroup = user.groups[i]
            this.selectedGroups.push(singleGroup.id)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
